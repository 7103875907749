$theme-colors: (
    "site-primary": #002e6b,
    "site-radio": #f2f8ff,
);

.custom_bg{
    background: #f2f8ff !important;
}

.scroll_to_top_btn {
    background: map-get($theme-colors, "site-primary");
    position: absolute;
    right: 20px;
    z-index: 10;
    border: none;
    border-radius: 50px;
    width: 55px;
    height: 55px;
    margin-top: -28px;
}

.scroll_to_top_icon {
    width: 20px;
    height: 30px;
}

.scroll_to_top_btn:focus-visible {
    outline: none !important;
}

.contactForm_styling {
    h1 {
        font-family: "Hyundai-Sans-Regular";
        font-size: 31px;
        font-weight: 500;
        line-height: 41px;
    }
    h5 {
        font-family: "Hyundai-Sans-Regular";
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;

        b {
            font-family: "Hyundai-Sans-Regular";
            font-size: 25px;
            font-weight: 700;
            line-height: 33px;
        }
    }

    h6 {
        font-family: "Hyundai-Sans-Regular";
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }

    label {
        font-family: "Hyundai-Sans-Regular";
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .custom_form_border {
        font-family: "Hyundai-Sans-Regular";
        background-color: #FBFBFB;
        border: 1px solid #E4E4E4;
        border-radius: 12px;
        height: 60px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }

    hr {
        border: 1px solid map-get($theme-colors, "site-primary");
    }

    .custom_submit_btn {
        font-family: "Hyundai-Sans-Medium";
        font-size: 27px;
        font-weight: 500;
        line-height: 33px;
        padding: 20px 25px 20px 25px;
        color: #ffff;
        background: map-get($theme-colors, "site-primary");
        border: none;
        border-radius: 7px;
        &_loading{
            width: 345px;
        }
    }

    .custom_submit_icon {
        width: 40px;
        height: 25px;
    }

    p {
        font-family: "Hyundai-Sans-Regular";
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
    }

    @media screen and (min-width: 0px) and (max-width: 489px) {
        h1 {
            font-size: 31px;
            line-height: 41px;
        }
        h5 {
            font-size: 21px;
            line-height: 28px;
            b {
                font-size: 21px;
                line-height: 28px;
            }
        }
        label {
            font-size: 18px;
            line-height: 22px;
        }

        p {
            font-size: 11px;
            line-height: 20px;
        }

        .custom_submit_btn {
            font-size: 15px;
            line-height: 25px;
            padding: 15px 15px 15px 15px;
        }

        .custom_submit_icon {
            width: 35px;
            height: 20px;
        }
    }
}

// Thank you Page Font Styling

.thankyou_styling {
    h5 {
        color: map-get($theme-colors, "site-primary");
        font-family: "Hyundai-Sans-Regular";
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;
    }

    @media screen and (min-width: 0px) and (max-width: 489px) {
        h5 {
            font-size: 21px;
            line-height: 28px;
        }
    }
}
.input_bg{
    input[type="radio"]{
        background-color: white !important;
        border : 2px solid #D4DCE4;
        appearance: none;
    }
   
}

.custom_font_size{
    font-size: 20px !important;
    b{
        font-family: "Hyundai-Sans-Medium" !important;
        font-size: 20px !important;
    }
}
.custom_font_size_h6{
    font-size: 14px !important;
}