.standard_equipment_section{
    background-color:  #F2F8FF;
    &_title{
        h1{
            font-family: "Hyundai-Sans-Medium" !important;
            font-weight: 700 !important;
        }
    }
    &_wrapper{
 
   &_card{    
    background-color: white;
    border: none !important;
    z-index: 9;
    box-shadow: 0px 31px 81px 0px #00114d33;
    border-radius: 20px;
    margin-bottom: 5rem;
    width: max-content;
    height: max-content;
    &_img_container{
        width: 57px !important;
        height: 50px !important;
    }
    h5{
        font-family: "Hyundai-Sans-Medium" !important;
    }
    
    @media screen and (max-width: 555.5px) {
        width: 90% !important;}
// @media screen and (max-width: 767.19px) {
//     width: max-content !important;}
   }

    }
}