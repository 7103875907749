$theme-colors: (
    "site-primary": #002e6b,
    "site-radio": #f2f8ff,
);

.theme-primary-color {
    color: map-get($theme-colors, "site-primary");
}

.slick-slider-hyundai {
    margin-bottom: 7rem !important;
    .slick-header {
        p {
            color: map-get($theme-colors, "site-primary");
            font-weight: 500;
            font-size: 16px;

            @media screen and (max-width: 768px) {
                font-size: 15px;
            }
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }

        h1 {
            color: map-get($theme-colors, "site-primary");
            font-weight: 700;
            font-size: 28px;
            font-family: "Hyundai-Sans-Medium";

            @media screen and (max-width: 768px) {
                font-size: 26px;
            }
            @media screen and (max-width: 576px) {
                font-size: 24px;
            }
        }
    }
    .slick-dots {
        top: 409px !important;
        padding-top: 31px;
    }

    .slick-body {
        // height: 370px;
        height: 450px;
        overflow: hidden;

        .slick-slide {
            padding: 0 10px;
        }

        .slick-dots li button:before {
            content: none;
        }

        .slick-dots li {
            display: inline-block;
            margin: 0px 5px;
            width: 10px;
            height: 5px;
            background-color: #c4c4c4;
            border-radius: 2px;
        }

        .slick-dots li.slick-active {
            background-color: map-get($theme-colors, "site-primary");
            width: 30px;
        }

        .img-container {
            height: 380px;
            border-radius: 30px;
        }

        .img-1 {
            background: url("../img/SliderImage1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .img-2 {
            background: url("../img/SliderImage2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .img-3 {
            background: url("../img/SliderImage3.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .img-4 {
            background: url("../img/SliderImage4.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .kona_img-1{
            background: url("../img/konaSlickSlider_1.jpeg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;  
        }
        .kona_img-2{
            background: url("../img/konaSlickSlider_2.jpeg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;  
        }
        .kona_img-3{
            background: url("../img/konaSlickSlider_3.jpeg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;  
        }
        .kona_img-4{
            background: url("../img/konaSlickSlider_4.jpeg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;  
        }
    }
}

.footer-text,
.footer-text a {
    font-size: 14px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
    @media screen and (max-width: 576px) {
        font-size: 10px;
    }
}
