
.features{
    background-color:  #F2F8FF;
    .feature-title{
        width: 378px;
    }
    .table-container{
        width: 100%;
        overflow-x: auto;
       span{
        padding: 0 0.75rem !important;
        }
    }
.bg-blue{
    background-color:#002e6b ;
    color: #f0f0f0;
    
}
.title{
    font-size: 2.5em;
    font-family: "Hyundai-Sans-Medium";
    font-weight: 700;
    @media screen and (max-width: 1024px) {
        font-size: 2.2em;
}
    @media screen and (max-width: 768px) {
        font-size: 1.7em;
}
@media screen and (max-width: 428px) {
    font-size: 1.6em;
    text-align: center;
}}



thead{
    th{
        border: 8px solid #F2F8FF ;
        font-family: "Hyundai-Sans-Bold";
        border-radius: 38px !important ;
         height: 95px;
       padding: 0 0 !important;
       .bg-blue-span {
        text-align: center ;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        font-size:  18px;
        background-color: #002e6b;
        border: 1px solid #002e6b;
        border-radius: 7px !important;
        border-bottom: 0px !important ;
        
      }
      .bg-blue-span-text{
        padding-bottom: 0px !important;
      }
     
      .white-span {
        text-align: center ;
        border: 1px solid #E2E4FB !important;
        color:#231E41;
        padding-top: 10px !important;
        flex-wrap: nowrap;
        padding-bottom: 10px !important;
        font-size:  18px;
        border: 1px solid #E2E4FB;
        border-radius: 7px !important;
      
      }
    }
    
}

}
.features-pricing {
    background-color: white !important;
    .table-container{
        width: 100%;
        overflow-x: auto;
        .custom_padding{
            padding: 0 5.5px !important;
        }
       span{
        padding: 0 0.75rem !important;

         span{
           
            border-bottom: 1px solid #E2E4FB ;
        }}
    }
.bg-blue{
    background-color:#002e6b ;
    color: #f0f0f0;
    
}
.title{
    font-family: "Hyundai-Sans-Medium";
    font-weight: 700;
    font-size: 2.5em;
   // font-family: "Hyundai-Sans-bold";
    @media screen and (max-width: 1024px) {
        font-size: 2.2em;
}
    @media screen and (max-width: 768px) {
        font-size: 1.7em;
}
@media screen and (max-width: 428px) {
    font-size: 1.6em;
}

@media screen and (max-width: 375.5px) {
    font-size: 1.9em;
}}



thead{
    th{
       font-family: "Hyundai-Sans-Medium";
       font-weight: 700;
        background-color: #ffff !important;
        color:#231E41 ;
        border: 10px solid white ;
        border-radius: 19px !important ;
         height: 100px;
         width: 230px;
         padding: 0 0.75rem !important;
       .bg-blue-span {
  display: flex;
  justify-content: center;
  align-items: center;
 
        width: 100% !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 20px !important;
        font-size:  20px;
        background-color: #002e6b;
        border: 1px solid #002e6b;
        border-radius: 4px !important;
        border-bottom: 0px !important ;
        p{
           // font-family: "Hyundai-Sans-Medium";
            width: 80% !important;
            font-size: 20px;
            margin: 0px !important;
        }
      }
      .white-span {
       // width: 99% !important;
       border: 1px solid #E2E4FB !important;
       color:#231E41;;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 20px !important;
        font-size:  20px;
        border: 1px solid #E2E4FB;
        border-radius: 4px !important;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    
}
.scroll-buttons{
    left: 40%;
}






.max-w{
    width: max-content !important;
}
.pricing-first-column {
    background-color: #ffff;
}
.bg-span{
    background-color: #F9FCFF !important
    ;
}
}

.scroll-buttons{
    left: 40%;
    visibility: hidden;
    @media screen and (max-width: 1024px) {
        visibility: hidden;
}
    @media screen and (max-width: 992px) {
        visibility: visible;}
@media screen and (max-width: 767px) {
    visibility: visible;}
}
.pricing-scroll-buttons{
    left: 40%;
    visibility: hidden;
    @media screen and (max-width: 1024px) {
        visibility: hidden;
}
    @media screen and (max-width: 991.5px) {
        visibility: visible;}
@media screen and (max-width: 767.19px) {
    visibility: visible;}
}
.modal-scroll-buttons{
    left: 40%;
    visibility: hidden;
    @media screen and (max-width: 1024px) {
        visibility: hidden;
}
    @media screen and (max-width: 991.5px) {
        visibility: visible;}

}

tbody{
    tr{
        td{
          
            background-color: #ffff;
            color:#231E41;
            border: 10px solid #F2F8FF ;
            border-bottom: 0px;
            padding: 0 0.75rem !important;
            height: 60px;
            box-shadow: #C1C1C1;
            margin-left: 19px !important;
            width: max-content;
            border-top: none  transparent  !important;
        }
    }
  
.first-row{
  
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  .blue-span{
    
    border-top: 1px solid #002e6b;
    border-left: 1px solid #002e6b;
    border-right:1px solid #002e6b;
    border-top-left-radius: 8px;
    border-top-right-radius:8px;
    display: flex;
    padding: 0 0.75rem !important;
    span{
        font-size:  14px !important;
        border-bottom:  1px solid #E2E4FB !important;
    }
  }
  
  .parent-span{
    
    border-top-left-radius: 8px;
    border-top-right-radius:8px;
    border-top: 1px solid #E2E4FB !important;
    border-left: 1px solid #E2E4FB !important;
    border-right:1px solid #E2E4FB !important;
   display: flex;
    padding: 0 0.75rem !important;
    span{
        font-size:  14px !important;
        border-bottom:  1px solid #E2E4FB ;
    }
  }

}
.child-row{ 
  .blue-span{
    display: flex;
    padding: 0 0.75rem !important;
    span{
        font-size:  14px !important;
        border-bottom:  1px solid #E2E4FB !important;
    }
  }
  .parent-span{
    border-left: 1px solid 
    #E2E4FB !important;
    border-right: 1px solid 
    #E2E4FB !important;
   display: flex;
    padding: 0 0.75rem !important;
    span{
        font-size:  14px !important;
        border-bottom:  1px solid #E2E4FB ;
    }
  }}
.lastrow{
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
    .blue-span{
        background-color:#002e6b ;
        border-bottom: 1px solid #002e6b;
        border-left: 1px solid #002e6b;
        border-right:1px solid #002e6b;
        border-bottom-left-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
        display: flex;
        padding: 0 0.75rem !important;
        span{
            font-size:  14px !important;
            border-bottom:  0px solid transparent !important;
        }
      }
    .parent-span{
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
        border-bottom: 1px solid 
        #E2E4FB !important;
        border-left: 1px solid 
        #E2E4FB !important;
        border-right: 1px solid 
        #E2E4FB !important;
        display: flex !important;
        padding: 0 0.75rem !important;
    span{
        font-size:  14px !important;
    border-bottom: 0px !important ;
    }}
}
}

.max-w{
    width: max-content !important;
}
.pricing-first-column {
    background-color: #F9FCFF;
}


.pricing-table-body{
    tr{
        td{
            background-color: #ffff;
            border: 10px solid #ffff ;
            border-bottom: 0px;
            padding: 0 0.75rem !important;
            height: 55px;
           // box-shadow: #C1C1C1;
            margin-left: 20px !important;
            width: max-content;
            border-top: 1px solid transparent  !important;
        }

        .first-row{
            height: 84px !important;
           span{
            span{
            align-items: end !important;
            padding-bottom:  16px !important;
            }
           }
        }

        .lastrow{
            height: 81px !important;
            span{
                span{
                align-items: start !important;
                padding-top:  16px !important;
                }
               }
        }
    }
}
.modal_header {
    background-color: #F2F8FF;
    border-bottom: 0px;

}
.modal_body{
    background-color: #F2F8FF;
    padding-top: 0px;
    
}

.table_modal_title{
   
    font-family: "Hyundai-Sans-Bold";
}
.modal_header_div{
    background-color: #F2F8FF !important;
}
.w-max{
    width: max-content !important;
}
.custom_table_line{
    width: 250px;
    height: 0.5px;
    background-color: #E2E4FB;
}
.border-bottom {
    border-bottom: none !important;
}

.w_responsive {
    width:  max-content;
    @media screen and (min-width:1199.99px) {
        width: 100%;
        
    }
}
.modal-content{
    border-radius: 9px ;
  //  border-top-left-radius: 9px;
}
.custom_rounded{
  border-top-right-radius: 9px ;
  border-top-left-radius: 9px;
}
.modal_header_radius {
    border-top-left-radius: 9px;
}
.modal_body_radius{
    border-bottom-right-radius: 9px ;
  border-bottom-left-radius: 9px; 
}
.custom_button_margin{
    margin-bottom: 90px;
    margin-top: 40px;
}
.border-radius{
    border-radius: 7px !important;
}
.custom_subtext_font{
    font-family: "Hyundai-Sans-Medium";
    font-weight: 400;
}
.custom-w-size{
   
    @media screen and (max-width: 991.5px) {
        width: max-content !important;}
@media screen and (max-width: 767.19px) {
    width: max-content !important;}
 
}
.w-title{
    width: 580px;
    text-align: center;
    @media screen and (max-width: 1024.5px) {
        width: 540px !important;}
    @media screen and (max-width: 768.5px) {
        width: 423px !important;}
@media screen and (max-width: 375.5px) {
    width: 229px !important;}

}
.price_section_row_data_margin{
    margin-left: 1.3rem !important;
   
}
.test_align_left{
    text-align: left;
}