.inclusive-section {
    background: #f2f8ff !important;
    padding-top: 80px;
    padding-bottom: 150px;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &__img {
        width: 100%;
        height: 780px;
        object-fit: cover;
        border-radius: 0px 100px 100px 0px;
    }

    &__video {
        justify-content: end;
        width: 100%;
        height: 780px;
        object-fit: cover;
        border-radius: 0px 100px 100px 0px;
    }

    &__title {
        font-size: 48px;
        font-weight: 700;
        font-family: "Hyundai-Sans-Medium";
    }

    &__sub-title {
        font-size: 18px;
        font-weight: 600;
    }

    ul {
        margin-left: 25px;
        color: #000000;
        // font-size: 16px !important;
    }

    .inclusive-section__list_konazakelijk {
        li {
            padding-left: 8px;
            line-height: 29px;
            word-spacing: 1.5px;
        }
    }

    li {
        font-size: 16px !important;
    }

    &__bottom {
        margin-top: -230px;
        // position: absolute;
        // bottom: 150px;
        // left: 50%;
        // transform: translate(-45%, 0);
    }

    &__kona_bottom {
        margin-top: -230px;
        // position: absolute;
        // bottom: 150px;
        // left: 50%;
        // transform: translate(-45%, 0);
    }

    &__card {
        border-radius: 16px !important;
        position: relative;
        box-shadow: 0px 26.982072830200195px 41.51088333129883px 0px #263a7e24;
        width: 235px;

        &-icon {
        }

        &-text {
            font-family: "Hyundai-Sans-Bold";
            font-size: 18px;
        }
    }

    @media (max-width: 1262.99px) {
        padding-top: 60px;
        padding-bottom: 60px;

        &__img {
            width: 100%;
            height: 750px;
        }

        &__video {
            width: 100%;
            height: 750px;
        }

        &__title {
            font-size: 37px;
        }

        &__sub-title {
            font-size: 16px;
        }

        ul {
            margin-left: 20px;
            color: #000000;
        }

        &__bottom {
            margin-top: -230px;
        }

        &__kona_bottom {
            margin-top: -230px;
        }

        &__card {
            width: 235px;

            &-text {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 991.99px) {
        padding-top: 0px;
        padding-bottom: 50px;

        &__img {
            height: 600px;
        }

        &__video {
            justify-content: start;
            height: 600px;
        }

        &__title {
            font-size: 35px;
        }

        &__sub-title {
            font-size: 15px;
        }

        ul {
            margin-left: 20px;
            color: #000000;
        }

        &__bottom {
            margin-top: -90px !important;
        }

        &__kona_bottom {
            margin-top: -90px !important;
        }

        &__card {
            &-text {
                font-size: 15px;
            }
        }
    }

    @media (max-width: 767.99px) {
        // padding-top: 45px;
        padding-bottom: 45px;

        &__img {
            height: 400px;
            border-radius: 0px !important;
        }

        &__video {
            height: 400px;
            border-radius: 0px !important;
        }

        &__title {
            font-size: 30px;
        }

        &__sub-title {
            font-size: 14px;
        }

        &__card {
            &-text {
                font-size: 14px;
            }
        }

        &__bottom {
            margin-top: -70px !important;
        }

        &__kona_bottom {
            margin-top: 34px !important;
        }
    }

    @media (max-width: 513.99px) {
        // padding-top: 40px;
        padding-bottom: 40px;

        &__img {
            height: 300px;
        }

        &__video {
            justify-content: start;
            height: 300px;
            padding-left: 0% !important;
        }

        &__title {
            font-size: 22px;
        }

        &__sub-title {
            font-size: 12px;
        }

        &__card {
            margin-left: 0.5rem !important;
            margin-top: 26px !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &-text {
                font-size: 12px;
            }
        }
        &__kona_bottom {
            margin-top: 120px !important;
        }
    }

    .line-height {
        line-height: 28px !important;
    }
}
