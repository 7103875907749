$theme-colors: (
    "site-primary": #002e6b,
    "site-radio": #f2f8ff,
    "radio-color": #5f75ee,
);

.cursor-pointer {
    cursor: pointer;
}

.theme-primary-color {
    color: map-get($theme-colors, "site-primary");
}

input[type="radio"]{
    background-color: #F2F8FF !important;
    border : 2px solid #808191;
    appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 18px;
   
}

input[type="radio"]:checked {
   appearance:auto !important;
    // border-radius: 20px;
}

input[type="checkbox"] {
    border: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(26px) !important;
}

.custom-switch .custom-control-label::before {
    width: 56px !important;
    height: 30px !important;
    border-radius: 16px !important;
}

.custom-switch .custom-control-label::after {
    width: 26px !important;
    height: 26px !important;
    border-radius: 32px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: map-get($theme-colors, "radio-color") !important;
    background-color: map-get($theme-colors, "radio-color") !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
}
.form-check-input {
    accent-color: map-get($theme-colors, "radio-color");
}

// Bootsrap Classes for Radio and Switches

.bg_img_container {
    background-image: url(../img/slider_Img_11.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // height: 100vh;
    &_background {
        background: map-get($theme-colors, "site-radio");
        background-size: cover;
        background-position: center;
    }
    &_back_btn {
         font-family: "Hyundai-Sans-Regular" !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        color: map-get($theme-colors, "site-primary");
        background-color: transparent;
        border: none;
    }
    &_back_icon {
        width: 24px;
        height: 24.62px;
    }
    &_next_btn {
        background-color: map-get($theme-colors, "site-primary");
        font-family: "Hyundai-Sans-Medium";
        font-size: 23px;
        font-weight: 500;
        line-height: 33.37px;
        border-radius: 7px;
        padding: 1rem;
    }
    &_next_icon {
        width: 24px;
        height: 24px;
    }

    .card_padding {
        border-radius: 12px !important;
        box-shadow: 0px 31px 81px 0px #00114D33 !important;
    }
    
    label {
        color: #000A38;
        font-size: 21px;
        font-weight: 400;
        line-height: 25.96px;
    }
    .custom_bold_text_siz{
        font-size: 21px !important;
        font-family: "Hyundai-Sans-Medium";
       // font-family: "H";
    }
    h1 {
        color: map-get($theme-colors, "site-primary");
        font-size: 48px;
        font-weight: 700;
        line-height: 59.33px;
    }
    h5 {
        color: map-get($theme-colors, "site-primary");
        font-size: 27px;
        font-weight: 400;
        line-height: 33.37px;
    }
    h6 {
        color: map-get($theme-colors, "site-primary");
        font-size: 18px;
        font-weight: 500;
        line-height: 22.25px;

        small {
            color: map-get($theme-colors, "site-primary");
            // font-family: "Montserrat";
            font-size: 18px;
            font-weight: 600;
            line-height: 32px;
        }
    }

    .custom_rightContent {
        background-color: map-get($theme-colors, "site-primary");
        font-family: "Hyundai-Sans-Medium";
        font-size: 21px;
        font-weight: 500;
        // line-height: 25.96px;
        padding: 10px 30px 10px 30px;
    }

   
    .card_padding {
        padding: 58px 116px; 
    }
    .card_custom_padding {
        padding: 58px 50px; 
    } 
    .custom_btn_position{
        position: absolute;
        right: 100px;
    }

    // Custom media queries for responsiveness

    @media screen and (min-width: 0px) and (max-width: 489px) {
        &_next_btn {
            font-size: 12px;
            padding: 9.32px 10px 9.32px 10px !important;
        }

        &_next_icon {
            width: 12.96px;
            height: 16.02px;
        }

        &_back_btn {
            font-size: 15px;
            line-height: 32px;
        }
        &_back_icon {
            width: 24px;
            height: 16.62px;
        }

        .custom-pad {
            padding: 1rem;
        }

        label {
            padding: 5px 0px 2px 12px !important;
            margin-left: 0px !important;
            font-size: 12px;
            line-height: 19.78px;
        }
        .custom_bold_text_siz {
            font-size: 12px !important;
        }
        h1 {
            font-size: 32px;
            line-height: 39.55px;
        }

        h5 {
            font-size: 19px;
            line-height: 23.48px;
        }

        h6 {
            font-size: 13.56px;
            line-height: 16.77px;
            small {
                font-size: 11px;
                line-height: 20px;
            }
        }

        .card_padding {
            padding: 15px 15px;
        }

        .custom_rightContent {
            font-size: 12px;
            padding: 4px 8px 2px 8px !important;
        }

        .custom_btn_position{
            right: 20px;
        }

        input[type="radio"],
        input[type="checkbox"] {
            width: 16px;
            height: 16px;
        }

        .custom-switch .custom-control-label::before {
            width: 34px !important;
            height: 18px !important;
        }
        .custom-switch .custom-control-label::after {
            width: 14px !important;
            height: 14px !important;
        }

        .custom-switch
            .custom-control-input:checked
            ~ .custom-control-label::after {
            transform: translateX(16px) !important;
        }
        .question_text {
            font-size: 18px !important;
            }
    }
    @media screen and (min-width: 490px) and (max-width: 991.5px) {
        &_next_btn {
            font-size: 18px;
            padding: 1rem;
        }

        &_next_icon {
            width: 20px;
            height: 20px;
        }

        .card_padding {
            padding: 30px 40px;
        }

        label {
            font-size: 18px;
        }
        .custom_bold_text_siz {
            font-size: 18px !important;
        }

        h1 {
            font-size: 45px;
        }
        h5 {
            font-size: 24px;
        }
        h6 {
            font-size: 15px;
            small {
                font-size: 15px;
            }
        }

        .custom_rightContent {
            font-size: 18px;
        }

        .custom_btn_position{
            right: 50px;
        }
        .question_text {
        font-size: 23px !important;
        }
    }
    @media screen and (min-width: 992px) and (max-width: 1005px) {
        .card_padding {
            padding: 40px 70px;
        }
      
        .question_text {
            font-size: 30px !important;
            }
    }
    @media screen and (min-width: 1005.5px) and (max-width: 1024px) {
        &_next_btn {
            font-size: 18px;
            padding: 1rem;
        }

        &_next_icon {
            width: 20px;
            height: 20px;
        }

        &_back_btn {
            font-size: 15px;
        }

        .card_padding {
            padding: 40px 81px;
        }

        label {
            font-size: 18px;
        }
        .custom_bold_text_size {
            font-size: 18px !important;
        }

        h1 {
            font-size: 45px;
        }
        h5 {
            font-size: 24px;
        }
        h6 {
            font-size: 15px;
            small {
                font-size: 15px;
            }
        }

        .custom_rightContent {
            font-size: 16px;
        }

        .custom_btn_position{
            right: 50px;
        }
        .question_text {
            font-size: 34px !important;
            }
    }
    
}
.bg_img_container_kona{
    background-image: url(../img/kona_questionModal-croped.jpeg) !important;

}
.font-weight-bold{
    font-weight: 700 !important;
}
.font-weight-bold-500 {
    font-family: "Hyundai-Sans-Medium" !important;
    font-weight: 700 !important;
}

// Bootsrap Classes for Radio and Switches
